import MainPage from "./page/mainpage"

function App() {
  return (
    <div style={{width:"100vw", height:"100vh", padding: "0px"}} >
      <MainPage/>
    </div>
  );
}

export default App;
